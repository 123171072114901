<template>
  <span
    :style="priorityColor"
    class="problemPriority"
  >{{ priorityText }}</span>
</template>

<script>
export default {
  name: "ProblemPriority",
  props: {
    priority: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      priorityMapping: [
        { key: 0, name: "not classified", color: "darkgray" },
        { key: 1, name: "info", color: "gray" },
        { key: 2, name: "warning", color: "gray" },
        { key: 3, name: "average", color: "gold" },
        { key: 4, name: "high", color: "orange" },
        { key: 5, name: "disaster", color: "red" }
      ]
    }
  },
  computed: {
    priorityText: function () {
      let item = this.getPriority();
      if (item) return item.name;
      return "unknown";
    },
    priorityColor: function () {
      let item = this.getPriority();
      if (item) return 'color: ' + item.color;
      return "color: red";
    }
  },
  methods: {
    getPriority: function () {
      let priorityId = Number(this.priority);
      return this.priorityMapping.find(o=>o.key === priorityId);
    }
  }
}
</script>
